import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This is page description`}</p>
    </PageDescription>
    <Tabs mdxType="Tabs">
      <Tab label="Type" mdxType="Tab">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.86111111111111%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAAqElEQVQY022PjQ7DIAiE+/7vuYrD7MclbVm1t0GGdU1J0PiFO86hlIJlWSAi0Nq2zd7zPGNdV2O1VsiB+Zx2zwYFgQg55yYOgcDMmKbJmC4dQwDF+MeYE1JKtqgzFNzuD3jpIF0ZfZVScRkJOutCbU/mTGsQeSNekyUz8femyE3sqUeKeObXLv4ZuHlLqIea9FAT+XtnpS11k6OZJey/1psct5/NnBl+AI5f2FGUNFk4AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "gambar",
            "title": "gambar",
            "src": "/static/c6ec224607ef334e00f4d21206038a4c/3cbba/tab-1.png",
            "srcSet": ["/static/c6ec224607ef334e00f4d21206038a4c/7fc1e/tab-1.png 288w", "/static/c6ec224607ef334e00f4d21206038a4c/a5df1/tab-1.png 576w", "/static/c6ec224607ef334e00f4d21206038a4c/3cbba/tab-1.png 1152w", "/static/c6ec224607ef334e00f4d21206038a4c/5dce9/tab-1.png 1500w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Tab>
      <Tab label="Specs" mdxType="Tab">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.86111111111111%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAA+ElEQVQY021QwU6EMBDlf8nun/ADnODCD6wHTroKiwQSjBovhMtixI0bEjFACxSeTiMVzL5k2pnXN9OZ0YQQ4Jyj6zoQpmlS/hKcM6kbhkHpGGNo23bFaSQqindUVSXJcRyR56+wLAumacK2bfi+j4/z+UdXyAKzrq5rNE0jP1oU7NCwP0KIEW/FCYZhQNd1bDcbOI6Dz69adUs66ioMQ0RRtJpO6/se+9s7ZFn2SwJPzy845vlq5OThEdc3e9kVgYrEcYzgcJBdqoJ0eJ6HJElUchDcw3Vd6dOOCWmaYre7QlmWKnmJOdb+k0vhHF9KJqM9ks3vdH8DwM7GDqxfOrEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "gambar",
            "title": "gambar",
            "src": "/static/b0c7d3c60691fc8d90d8d85ad4ce3b8b/3cbba/tab-2.png",
            "srcSet": ["/static/b0c7d3c60691fc8d90d8d85ad4ce3b8b/7fc1e/tab-2.png 288w", "/static/b0c7d3c60691fc8d90d8d85ad4ce3b8b/a5df1/tab-2.png 576w", "/static/b0c7d3c60691fc8d90d8d85ad4ce3b8b/3cbba/tab-2.png 1152w", "/static/b0c7d3c60691fc8d90d8d85ad4ce3b8b/5dce9/tab-2.png 1500w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Tab>
      <Tab label="Dimensions" mdxType="Tab">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.86111111111111%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAA9ElEQVQY011PQU7DMBD0/x/BPzhw4IBA4gKXAg1IpU1w2kaBRK3jrb0e1naTBlZarXY8MztW3ntYOoE8I1YIAdYSBnJwzs2wAcNJMOEnTNpaC5uwi1ZZIuhyjb7eJJCZoasK+9USxpiExaN612C3KmD6n8w7fKNppdcfGLp2ZugY7ec7UNxnMQfUVQksboXBKUvMpJ/u4G6uADqmdHi+hisegfIFaDYZE74iibzVGvz2IGe9GDJqSWNfZTddTiPsr2KBbr+dkiQD06eZzTKm4mP8UnA0kac9hAsWjUfhWZxaAoxmcSrMKuBvhbPZtI3Cf8Zzw1/nIdPiu57BKwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "gambar",
            "title": "gambar",
            "src": "/static/fa63e2ba9346aa525d3625b34df30dbb/3cbba/tab-3.png",
            "srcSet": ["/static/fa63e2ba9346aa525d3625b34df30dbb/7fc1e/tab-3.png 288w", "/static/fa63e2ba9346aa525d3625b34df30dbb/a5df1/tab-3.png 576w", "/static/fa63e2ba9346aa525d3625b34df30dbb/3cbba/tab-3.png 1152w", "/static/fa63e2ba9346aa525d3625b34df30dbb/5dce9/tab-3.png 1500w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Tab>
      <Tab label="Spacing" mdxType="Tab">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.86111111111111%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAA5klEQVQY01WQy26DMBBF+f9v6rZSNnTVVZUqaSEFotDQlBi/T22IwR1pFr5z5npmCmstWiqs1njAeY+SEj1JjDHEcM5hIhMyaT5wRqmlN9MKqzSH5ot66Fm6PXXb8taemMS0amV9oDwduQsxS8JoyuaD47llEONmWIkb5aWh93oWey3ZdRWdk6S4GslT/c7zTztvMXNW8jJeKKdvJG4zPE8jVdeRyJtR7MPEKLM2/4ZpXutPumFYhFAQznK1itFt68YoYtGHG8XbJYP0zsG4Ng9m1RKfscVms/zsc+MM9Ev5v/bInP0D9kvVFLbl0OcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "gambar",
            "title": "gambar",
            "src": "/static/e17fdaac78e90fedb560c18d6b77ff1f/3cbba/tab-4.png",
            "srcSet": ["/static/e17fdaac78e90fedb560c18d6b77ff1f/7fc1e/tab-4.png 288w", "/static/e17fdaac78e90fedb560c18d6b77ff1f/a5df1/tab-4.png 576w", "/static/e17fdaac78e90fedb560c18d6b77ff1f/3cbba/tab-4.png 1152w", "/static/e17fdaac78e90fedb560c18d6b77ff1f/5dce9/tab-4.png 1500w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Tab>
    </Tabs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      